import { styled } from '@mui/system';
import { ExtLink } from 'components';
import { Typography } from '@mui/material';
import React from 'react';

interface ExtLinkNoUnderlineProps {
  href?: string;
  children: React.ReactNode;
}

export const ExtLinkNoUnderline: React.FC<ExtLinkNoUnderlineProps> = ({ href, children }) => {
  // Check if href is empty
  if (!href) {
    // Return the children inside Typography (without link behavior)
    return <Typography>{children}</Typography>;
  }

  // If href exists, use the styled ExtLink
  const StyledExtLink = styled(ExtLink)({
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  });

  return <StyledExtLink href={href}>{children}</StyledExtLink>;
};
